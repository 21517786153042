import { Footer } from '@/components'
import React from 'react'
import AthleteCard from './athlete-card'
import BlogSection from './blog-cta'
import CoachCard from './coach-card'
import CTADark from './cta-dark'
import CTAOrange from './cta-orange'
import FAQ from './faq'
import Hero from './hero'
import Numbers from './numbers'
import PricingSection from './pricing'

export default function LandingPage() {
	return (
		<div className='relative w-full min-h-screen bg-[#f3f4f6]'>
			<Hero />
			<AthleteCard />
			<CoachCard />
			<Numbers />
			<BlogSection />
			<PricingSection />
			<CTADark />
			<FAQ />
			<CTAOrange />
			<Footer />
		</div>
	)
}
