'use client'

import { ClientHome } from '@/components'
import { GradientSpinner } from '@/components/ui/spinner'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import React, { useEffect } from 'react'
import LandingPage from './lander/page'

export default function Home() {
	const { data: session, status } = useSession()
	const router = useRouter()

	useEffect(() => {
		if (status === 'authenticated' && session?.user?.role) {
			const userRole = session.user.role
			if (userRole === 'ADMIN' || userRole === 'COACH') {
				router.replace('/dashboard')
			}
		}
	}, [session, status, router])

	if (status === 'loading') {
		return <GradientSpinner />
	}

	if (status === 'unauthenticated') {
		return <LandingPage />
	}

	if (status === 'authenticated' && session?.user?.role) {
		const userRole = session.user.role

		if (userRole === 'CLIENT') {
			return <ClientHome />
		}

		if (userRole === 'ADMIN' || userRole === 'COACH') {
			router.replace('/dashboard')
			return null
		}
	}

	return <div>Redirecting...</div>
}
